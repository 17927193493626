import Image from "next/image";
import Link from "next/link";
import CollectionBanner from "./collection/Banner";

export default function SellYourCarCTA() {
  return (
    <div className="bg-white mt-6">
      <div className="mx-auto max-w-7xl sm:px-4">
        <div className="overflow-hidden rounded-lg sm:shadow-lg lg:grid lg:grid-cols-2 lg:gap-4 border-t border-b border-gray-200 sm:border sm:border-gray-100">
          <div className="px-0 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                <span className="block">¿Estás vendiendo tu auto?</span>
                {/* <span className="block">Publicalo.</span> */}
              </h2>
              <p className="mt-3 text-lg leading-6 text-gray-500">
                Publicar tu auto te tomará tan solo unos minutos y sin ningún
                costo. Podés pagar por mayor visibilidad y alcanzar a cientos de
                personas interesadas.
              </p>
              <Link
                href="/vender"
                // onClick={() => trackBannerClick(trackId)}
                className="ml-1"
              >
                <span className="font-semibold leading-6 text-orange-500 hover:text-orange-400">
                  Publicar
                  <span aria-hidden="true">→</span>
                </span>
              </Link>
            </div>
            <div className="lg:self-center mt-8">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                <span className="block">
                  ¿Tenés a la venta un auto premium o de lujo?
                </span>
                {/* <span className="block">Publicalo.</span> */}
              </h2>
              <div className="mt-6">
                <CollectionBanner />
              </div>
            </div>
          </div>
          <div className="aspect-w-5 aspect-h-3 -mt-6 lg:aspect-w-3 lg:aspect-h-4">
            <div className="hidden lg:block translate-x-6 translate-y-12 transform sm:translate-x-16">
              <Image
                src="/cta_screenshot_vertical.png"
                alt="Vendé tu auto en Movi"
                fill
                sizes="40vw"
                style={{
                  objectFit: "cover",
                }}
                className="rounded-md object-left-top"
              />
            </div>
            <div className="block lg:hidden translate-x-6 translate-y-12 transform sm:translate-x-16">
              <Image
                src="/cta_screenshot_horizontal.png"
                alt="Vendé tu auto en Movi"
                fill
                sizes="90vw"
                style={{
                  objectFit: "cover",
                }}
                className="rounded-md object-left-top"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
